body {
  margin: 100px;
  font-family: Open Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 1rem;
  text-align: left;
  color: #eeeeee;
}


code {
  font-family:
    monospace;
}

